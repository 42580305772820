import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./App.css";
import Routes from "./Routes";

function App() {
  return (
    <div className="App container">
    <Navbar bg="light" variant="light">
    <Navbar.Brand href="#home">
      <img
        alt=""
        src="/logo192.png"
        width="30"
        height="30"
        className="d-inline-block align-top"
      />{' '}
      Sumo Art
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#portfolio">Portfolio</Nav.Link>
    </Nav>
    <Nav>
      <Nav.Link href="#features">About Us</Nav.Link>
      <Nav.Link href="#pricing">Order</Nav.Link>
      <Nav.Link href="#contact">Contact Us</Nav.Link>
    </Nav>
    </Navbar.Collapse>
  </Navbar>
      <Routes />
    </div>
  );
}

export default App;
