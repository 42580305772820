import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import "./ImageCarousel.css";


export default class ImageCarousel extends React.Component {
render(){
   return (<Carousel touch={true} slide={false}>
        <Carousel.Item>
					<img
						className="d-block w-100"
						src="https://picsum.photos/800/400?text=First slide&bg=282c34"
						alt="First slide"
					/>
					<Carousel.Caption>
						<Button variant="outline-primary" size="lg">View Our Posters</Button>
						<h3>Because I can't hide that feeling</h3>
						<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src="https://picsum.photos/800/400?text=Second slide&bg=282c34"
						alt="Third slide"
					/>

					<Carousel.Caption>
						<Button variant="outline-primary" size="lg">View Our Cards</Button>
						<h3>One of a kind designs</h3>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						className="d-block w-100"
						src="https://picsum.photos/800/400?text=Third slide&bg=20232a"
						alt="Third slide"
					/>

					<Carousel.Caption>
						<Button variant="outline-primary" size="lg">Order Now</Button>
						<h3>Wholesale prices</h3>
						<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
					</Carousel.Caption>
				</Carousel.Item>
        </Carousel>)
	}
}

