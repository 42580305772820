import React from "react";
import "./Home.css";
import ImageCarousel from '../components/ImageCarousel';

export default function Home() {

  return (
    <div className="Home">
      <div className="lander">
        <ImageCarousel/>
      </div>
    </div>
  );
}